@use '@/styles/utils/mixins.scss' as *;

.stickyNav {
  position: sticky;
  top: 0;
  z-index: 999;
}
.mainNav {
  background-color: $kiaMidnightBlack;
  width: 100%;
  padding: 0 160px;

  @include des-max() {
    padding: 0;
    padding-right: 160px;
  }
  @include tab-des() {
    padding-right: 120px;
  }
  @include tab() {
    padding-right: 0px;
  }

  a:hover {
    text-decoration: none;
  }

  .mainNavItem {
    margin: 0 24px;
    padding: 13px 0;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 600;
    color: $kiaPolarWhite;
    position: relative;
    @media screen and (min-width: 992px) and (max-width: 1145px) {
      font-size: 12px;
    }

    @include tab() {
      padding: 16px;
      border-bottom: 1px solid #cfd0d3;
      margin-right: 0;
      font-size: 18px;
    }

    &:hover {
      z-index: $headerNavItemZindex;
    }

    &:hover::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $kiaPolarWhite;
      position: absolute;
      left: 0;
      bottom: 12px;
    }
  }
}

.mainNavItemActive {
  z-index: $headerNavItemZindex;
}

.openSubmenu {
  width: 20px;
  height: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 100%;
  background: $sliderTrack;

  svg {
    width: 15px;
    height: 20px;
  }
}

.flexWrapper {
  @media screen and (max-width: 1366px) {
    padding: 0 24px;
  }
  @media screen and (max-width: 991.98px) {
    padding: 0 0 0 24px;
  }
  @include tab() {
    display: block;
    justify-content: space-between;
  }
  .drawerContent {
    width: auto !important;
  }
}

.KiaOneNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 1366px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li.menuItem {
      display: inline-flex;
      margin: 0 24px;
      @media screen and (min-width: 1024px) and (max-width: 1220px) {
        margin: 0 20px;
      }
      @media screen and (max-width: 1024px) {
        margin: 0 15px;
      }
      a {
        &.menuLink {
          padding: 13px 0;
          font-size: 14px;
          line-height: 16.8px;
          font-weight: 600;
          color: $kiaPolarWhite;
          position: relative;
          @media screen and (min-width: 992px) and (max-width: 1145px) {
            font-size: 12px;
          }
          &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 11px;
            height: 1px;
            width: 0;
            background-color: $kiaPolarWhite;
            transition: width 0.3s;
          }
          &:hover::before,
          &:focus::before,
          &:active::before {
            width: 100%;
            transition: width 0.3s;
          }
          a:active,
          a:hover {
            color: $kiaPolarWhite;
          }
        }
        &[aria-expanded='true'] {
          &::before {
            width: 100%;
            transition: width 0.3s;
          }
        }
      }
    }
  }
}

.iconsWrapper {
  @media screen and (min-width: 992px) {
    display: none;
  }
  display: flex;
  @media screen and (max-width: 991.98px) {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .logo {
      position: relative;
      left: 0;
      transform: translateX(0);
    }
  }
}
.micorsiteLogoWrap {
  @media screen and (min-width: 992x) {
    margin-top: 5px;
  }
  .micrositeLogo {
    margin-top: -10px;
    position: relative;
    @media screen and (max-width: 991.98px) {
      margin-top: 0;
    }
  }
  .micrositeName {
    margin-top: 5px;
    word-break: break-word;
  }
}

.priceDisclaimer {
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.5rem;
}

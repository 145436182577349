@use '../utils/vars.scss' as *;

.ant-upload-picture-card-wrapper {
  height: 100%;
}
.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
  background-color: $kiaPolarWhite;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: inherit;
}

.drag-upload-images.ant-upload {
  height: 154px;
}

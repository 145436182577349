@use './utils/vars.scss' as *;
@forward './utils/vars.scss';
@import './utils/fonts.scss';
@import './components/menu.scss';
@import './components/select.scss';
@import './components/popover.scss';
@import './components/checkbox.scss';
@import './components/radio.scss';
@import './components/input.scss';
@import './components/slider.scss';
@import './components/button.scss';
@import './components/pagination.scss';
@import './components/modal.scss';
@import './components/form.scss';
@import './components/carousel.scss';
@import './components/table.scss';
@import './components/datepicker.scss';
@import './components/upload.scss';
@import './components/tabs.scss';
@import './components/drawer.scss';
@import './components/l-radio.scss';
@import './components/image.scss';

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $sliderTrack;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $description;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $kiaMidnightBlack;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

html * {
  font-family: 'KiaSignature';
  box-sizing: border-box;
}

body {
  min-width: 360px;
  accent-color: $kiaMidnightBlack;

  .kia-admin {
    color: $adminBlack;
    background: $adminBackground;
  }

  .kia-client {
    color: $kiaMidnightBlack;
  }

  @media only screen and (max-width: 320px) {
    min-width: auto;
  }
}

.ant-spin {
  position: fixed !important;
  max-height: none !important;
}

.ant-spin-dot-item {
  background-color: black !important;
}

.ant-switch-checked {
  background-color: #05141f !important;
}

.overflow {
  overflow: hidden;
}

.app {
  font-family: sans-serif;
  min-width: 300px;
}

.app .gutter-left {
  margin-left: 9px;
}

.app .col-span-2 {
  grid-column: span 2;
}

.app .kia-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app p {
  text-align: center;
}

.app h1 {
  text-align: center;
  margin-left: 18px;
  font-size: 24px;
}

.app h2 {
  text-align: center;
  font-size: 20px;
  margin: 40px 0 10px 0;
}

.DraftEditor-root {
  padding: 0 15px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $adminBlack !important;
  color: white;

  &:after {
    border-right: $adminBlack !important;
  }
}

@media only screen and (max-width: 1024px) {
  // .gm-style {
  //   display: none;
  // }
  .gm-style .gm-style-iw-c {
    max-width: 300px !important;
  }
  .ant-modal {
    top: 50px !important;
  }
}

@media only screen and (max-width: 500px) {
  .ant-modal {
    top: 0 !important;
  }
}

.total {
  width: 86px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &.green {
    color: #547e19;
    background: rgba(109, 210, 48, 0.08);
  }

  &.red {
    color: #ff0018;
    background: rgba(255, 0, 24, 0.08);
  }

  &.yellow {
    color: #fcc100;
    background: rgba(252, 193, 0, 0.08);
  }
}

.dealership_managment_icon {
  margin-right: 15px;
}

.f-wrap {
  display: flex;
  flex-wrap: wrap;
}

.new-cars-logo {
  font-family: 'kiabold';
}

#CybotCookiebotDialog {
  font-family: 'KiaSignature' !important;
}

#CybotCookiebotDialogBodyContent {
  color: #05141f !important;
}

#CybotCookiebotDialogNavList {
  color: #05141f !important;
}

#CookiebotWidget {
  @media only screen and (max-width: 768px) {
    bottom: 75px !important;
  }
}

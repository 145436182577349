@use '../utils/vars.scss' as *;

.ant-modal-content {
  .ant-modal-close {
    top: 18px;
    right: 20px;
  }

  .ant-modal-close-x {
    width: 16px;
    height: 16px;
    line-height: 16px;

    svg {
      fill: $kiaPolarWhite;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
}

@use '../../styles/utils/vars.scss' as *;

@mixin placeholder-styles {
  width: 100%;
  height: 38px;

  & > .ant-select-selector:not(.ant-select-customize-input) {
    height: 100%;

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      color: $kiaMidnightBlack;
      line-height: 38px;
    }

    .ant-select-selection-placeholder {
      font-size: 13px;
      font-weight: bold;
    }
  }
}

.ant-select.ant-select-single.ant-select-show-search {
  box-sizing: border-box;
  width: 100%;
  min-width: 450px;
  flex-basis: 450px;
  flex-grow: 1;

  .ant-select-selector {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background-color: $background;
    border: 1px solid $border;
    border-radius: 0;
    outline: none;
    box-sizing: border-box;
    box-shadow: none;

    &:hover {
      border-color: $kiaMidnightBlack;
    }

    &::before {
      content: '';
      background-image: url('../../app/_icons/search.png');
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      height: 13px;
      width: 12px;
      margin-right: 12px;
      filter: opacity(0.5);
    }

    .ant-select-selection-search {
      margin-left: 28px;
    }

    .ant-select-selection-search-input,
    .ant-select-selection-placeholder {
      font-size: 16px;
      line-height: 18px;
      height: 100%;
    }

    .ant-select-selection-placeholder {
      line-height: 100%;
      height: 100%;
      color: #8a8b8a;
      display: flex;
      align-items: center;
    }

    .ant-select-selection-search-input {
      color: $kiaMidnightBlack;
    }
  }
}

.ant-select.ant-select-single.ant-select-show-search:not(
    .ant-select-customize-input
  ).ant-select-focused {
  outline: none;

  & > .ant-select-selector {
    box-shadow: none;
    border-color: $kiaMidnightBlack;

    &::before {
      filter: opacity(1);
    }
  }

  .ant-select-selection-placeholder {
    display: none;
  }
}

.action-select.ant-select,
.action-select .ant-select-selector,
.action-select .ant-select-selection-search,
.action-select .ant-select-selection-item {
  height: 19px !important;
  width: 75px;
}

.action-select {
  .ant-select-selector {
    padding: 0 !important;
  }

  .ant-select-selector .ant-select-selection-item,
  .ant-select-selector .ant-select-selection-placeholder {
    line-height: 1.2 !important;
  }

  .ant-select-selection-item {
    text-transform: none;
    font-weight: 500;
  }
}

.ant-select.dealers-select {
  @include placeholder-styles;
}

.ant-select.brand-select {
  @include placeholder-styles;
}

.ant-select {
  width: 100%;
}

.ant-select-single:not(.ant-select-customize-input).selectAdmin {
  .ant-select-selector {
    height: 40px;
  }
}

.ant-select-single.selectAdmin .ant-select-selector .ant-select-selection-item,
.ant-select-single.selectAdmin
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 40px;
}

.ant-select-dropdown .ant-select-item-group {
  font-size: 14px;
  color: #8a8b8a;
  margin: 10px 0 0 10px;
}

//.ant-select-dropdown {
//  @media screen and (max-width: $maxTablet) {
//    width: 100vw !important;
//    left: 0 !important;
//  }
//}

@use '../utils/vars.scss' as *;

.leads-tabs {
  .ant-tabs-tab .ant-tabs-tab-btn {
    color: $adminBlack;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    text-shadow: none;
  }

  .ant-tabs-ink-bar {
    background: $adminBlack;
  }
}


.dealership-dashboard-tabs {
  .ant-tabs-tab .ant-tabs-tab-btn {
    color: $adminBlack;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    text-shadow: none;
  }

  .ant-tabs-ink-bar {
    background: $adminBlack;
  }
  
  .ant-tabs-tab-btn[aria-selected="false"] {
    color: $disabledButtonText;
  }

  .ant-tabs-nav-list {
    padding: 0 20px;
  }

  .ant-tabs-nav {
    &:before {
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      border: 1px solid $disabledButtonText;
      z-index: 0;
    }
  }
}

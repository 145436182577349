@use '@/styles/utils/mixins.scss' as *;

.ant-slider.ant-slider-with-marks {
  &:hover {
    .ant-slider-track {
      background-color: $kiaMidnightBlack;
    }

    .ant-slider-rail {
      background-color: $sliderTrack;
    }

    .ant-slider-handle:not(.ant-open-tooltip) {
      border-color: $sliderTrack;
    }
    .ant-slider-handle,
    .ant-slider-handle-1,
    .ant-slider-handle-2 {
      &:before,
      &::after {
        content: '';
        box-shadow: none;
      }
      &:hover,
      &:active,
      &:focus {
        &:before,
        &::after {
          content: '';
          box-shadow: none;
          background-color: transparent;
        }
      }
    }
  }

  .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
    box-shadow: none;
    border-color: $sliderTrack;
  }

  .ant-slider-track {
    background-color: $kiaMidnightBlack;
    height: 2px;
    top: 2px;
  }

  .ant-slider-rail {
    background-color: $sliderTrack;
    height: 2px;
    top: 2px;
  }

  .ant-slider-handle,
  .ant-slider-handle-1,
  .ant-slider-handle-2 {
    width: 25px;
    height: 25px;
    border: solid 1px $sliderTrack;
    background-color: $kiaPolarWhite;
    margin-top: 0;
    transform: translate(-50%, -50%) !important;
    border-radius: 50px;
    box-shadow: none;
    &:before,
    &::after {
      content: '';
      box-shadow: none;
      background-color: transparent;
    }
    &:hover,
    &:active,
    &:focus {
      &:before,
      &::after {
        content: '';
        box-shadow: none;
        background-color: transparent;
      }
    }
  }

  .ant-slider-step {
    top: 2px;
  }

  .ant-slider-dot {
    top: -3px;
    box-shadow: 0 2px 6px 0 $sliderBoxShadow;
    background-color: $sliderTrack;
    border: none;
  }

  .ant-slider-mark {
    top: 16px;
  }

  .ant-slider-mark-text:last-of-type {
    right: -6px;
    left: initial !important;
    transform: unset !important;
  }
}

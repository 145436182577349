@use '../utils/vars.scss' as *;

.ant-picker-range-separator {
  margin-right: 20px;
}

.ant-select-selector {
  // padding: 20px;
  width: 100%;
  .ant-select-selection-item {
    // overflow: visible;
    // top: -13px;
    // font-size: 12px;
  }
}

.leads-datepicker {
  height: 40px;
  .ant-picker-input > input {
    font-size: 12px;
    color: $adminBlack;
  }

  .ant-picker-range-separator {
    margin: 0;
  }
}

.date-select-picker {
  .ant-picker-input > input::placeholder {
    color: $kiaMidnightBlack;
    line-height: 38px;
    font-size: 13px;
    font-weight: bold;
  }
}

@use '../utils/vars.scss' as *;

.ant-popover {
  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    box-shadow: none;
    border-radius: 0;
    border: solid 1px $border;
  }
}

.ant-popover.ant-popover-placement-bottomLeft {
  padding-top: 4px;
}

.extendedMenu.ant-popover-placement-bottom {
  padding-top: 0;
  z-index: $extendMenuPopoverZindex;

  .ant-popover-inner {
    transform: translateY(-4px);
    border: none;
  }
}

.ant-popover.image-tooltip {
  padding-top: 0;

  .ant-popover-inner {
    border-radius: 4px;
  }
}

.ant-popconfirm {
  .ant-popover-inner-content {
    padding: 12px 16px;
  }
}

.ant-message .anticon {
  top: -2px;
}

.action-radio.ant-radio-group {
  margin-left: 7px;

  .ant-radio {
    display: none;
  }

  .tile-view,
  .list-view {
    svg {
      transition: all 0.1s ease-out;
      fill: $kiaCoolGrey;
    }
  }

  .ant-radio-wrapper-checked {
    svg {
      fill: $kiaMidnightBlack;
    }
  }

  .ant-radio-wrapper {
    margin-right: 12px;
  }

  span.ant-radio + * {
    padding: 0;
  }

  .ant-radio-wrapper {
    line-height: 1;
  }
}

@use '../utils/vars.scss' as *;

.ant-menu.ant-menu-root {
  min-height: calc(100vh - 64px);
  height: calc(100% - 64px);

  .ant-menu-item,
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 64px;
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  .ant-menu-item > .ant-menu-title-content {
    display: block;
    white-space: normal;
    max-width: 180px;
  }

  .ant-menu-submenu-title > .ant-menu-title-content {
    display: block;
    white-space: normal;
    max-width: 180px;
  }

  .ant-menu-item-only-child {
    height: 42px;
    padding-left: 64px !important;
  }

  .ant-menu-item-icon {
    font-size: 24px;
    font-weight: bold;
  }

  .ant-menu-submenu-selected {
    color: $adminBlack;
  }

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    right: 10px;
  }

  .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ant-menu-item .ant-menu-item-icon + span {
    margin-left: 0;
  }
}

.dealership-side-menu.ant-menu.ant-menu-root {
  .ant-menu-item,
  .ant-menu-submenu-title {
    color: $kiaCoolGrey;
    align-items: flex-start;
    height: auto;
    justify-content: flex-start;
  }

  .ant-menu-item-selected {
    background-color: #f8fafb !important;
    color: $adminBlack;

    &:after {
      border-right: 3px solid $adminBlack;
    }
  }

  .ant-menu-item::after {
    border-right: 3px solid $adminBlack !important;
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: $background !important;
  }

  .ant-menu-item .ant-menu-title-content {
    display: flex;
    color: $kiaCoolGrey;
  }

  .ant-menu-item-selected .ant-menu-title-content {
    color: $adminBlack;
  }
}

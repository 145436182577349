@font-face {
  font-family: 'KiaSignature';
  font-display: fallback;
  src: url('../../public/fonts/KiaSignatureLight.eot');
  src:
    url('../../public/fonts/KiaSignatureLight.woff') format('woff'),
    url('../../public/fonts/KiaSignatureLight.woff2') format('woff2');
  font-weight: 200;
}

@font-face {
  font-family: 'KiaSignature';
  font-display: fallback;
  src: url('../../public/fonts/KiaSignatureRegular.eot');
  src:
    url('../../public/fonts/KiaSignatureRegular.woff') format('woff'),
    url('../../public/fonts/KiaSignatureRegular.woff2') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'KiaSignature';
  font-display: fallback;
  src: url('../../public/fonts/KiaSignatureBold.eot');
  src:
    url('../../public/fonts/KiaSignatureBold.woff') format('woff'),
    url('../../public/fonts/KiaSignatureBold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'kialight';
  font-display: fallback;
  src: url('../../public/fonts/KIAOTFL.otf'),
    url('../../public/fonts/KIA_Light.ttf');
}

@font-face {
  font-family: 'kiamedium';
  font-display: fallback;
  src: url('../../public/fonts/KIAOTFM.otf'),
    url('../../public/fonts/KIA_Medium.ttf');
}

@font-face {
  font-family: 'kiabold';
  font-display: fallback;
  src: url('../../public/fonts/KIAOTFB.otf'),
    url('../../public/fonts/KIA_Bold.ttf');
}

.thumb-slider {
  .image-wrapper {
    opacity: 0.6;
  }
  .slick-current {
    .image-wrapper {
      opacity: 1;
    }
  }
}

.car-slider {
  .slick-list {
    margin: 0 -9px;
  }
  .slick-slide {
    padding: 0 9px;
    box-sizing: border-box;
  }

  .slick-track {
    padding-bottom: 15px;
    display: flex;
  }
}

@media screen and (max-width: 1024px) {
  .custom-colour-slider .slick-list {
    padding: 5px 50px 0px !important;
  }

  .custom-colour-slider .slick-next:after,
  .custom-colour-slider .slick-prev:after {
    display: grid;
  }
}

#close-dealerships-slider,
#blog-slider {
  @media screen and (max-width: 1024px) {
    .slick-list {
      padding: 0 10% 0 0 !important;
    }
  }
}

@use '../utils/vars.scss' as *;

.details-table {
  .ant-table-cell {
    padding: 12px 16px;
  }
}

.report-table {
  .ant-table-cell {
    padding: 16px 20px;
  }
}

.leads-table,
.car-leads-table {
  border-top: 1px solid #f0f0f0;

  .ant-table-cell {
    padding: 28px 0;

    .ant-table-column-sorters {
      padding: 16px;
      justify-content: start;
    }

    .ant-table-column-sorter {
      margin-left: 8px;
    }
  }

  th.ant-table-cell {
    padding: 18px 0;
    color: $kiaCoolGrey;
    text-transform: uppercase;
    font-size: 12px;
    background: $kiaPolarWhite;
    font-weight: bold;
    cursor: pointer;
  }

  .lead-status-processed {
    background-color: $disabledButton;
    color: $adminBlack;
  }

  .lead-status-success {
    background-color: $kiaAdminBackgroundGreen;
    color: $kiaAdminGrey;
  }

  .lead-status-failed {
    background-color: $kiaAdminBackgroundRed;
    color: $kiaLiveRed;
  }

  .lead-status-retry {
    background-color: $kiaAdminBackgroundYellow;
    color: $kiaAfternoonYellow;
  }
}

.car-leads-table {
  border-top: none;
  .ant-table-cell {
    padding: 13px 0;
  }
}

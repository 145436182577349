@use '../utils/vars.scss' as *;

.results-pagination {
  .ant-pagination {
    .ant-pagination-mini .ant-pagination-item {
      margin: 0 9px 0 9px !important;
    }
  
    .ant-pagination-mini .ant-pagination-prev {
      margin-right: 7px;
    }
  
    .ant-pagination-mini .ant-pagination-next {
      margin-left: 7px;
    }
  
    .ant-pagination-mini .ant-pagination-next,
    .ant-pagination-mini .ant-pagination-prev {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  
    .ant-pagination-mini .ant-pagination-next.ant-pagination-disabled,
    .ant-pagination-mini .ant-pagination-prev.ant-pagination-disabled {
      opacity: 0.6;
    }
  
    .ant-pagination-item {
      a {
        display: inline;
        padding: 0;
        font-size: 16px;
        opacity: 0.6;
      }
    }
  
    .ant-pagination-item:hover a {
      color: $kiaMidnightBlack;
    }
  
    .ant-pagination-item-active:hover {
      border-color: transparent;
    }
  
    .ant-pagination-item-active {
      background: transparent;
      border-color: transparent;
      font-weight: bold;
  
      a {
        color: $kiaMidnightBlack;
        border-bottom: 1px solid $kiaMidnightBlack;
        opacity: 1;
      }
    }
  
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon {
      color: $kiaMidnightBlack;
    }
  }
}

@use '../utils/vars.scss' as *;

.ant-row.ant-form-item {
  margin-bottom: 10px;

  .ant-form-item-explain,
  .ant-form-item-extra {
    font-size: 12px;
    min-height: 18px;
  }

  .ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus {
    box-shadow: none;
  }
}

.step-form {
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }
}

@use '../utils/vars.scss' as *;

.ant-input.priceInput {
  height: 100%;
  box-sizing: border-box;
  padding: 7px 20px;
  border: solid 1px $border;
  background-color: $kiaPolarWhite;
  border-radius: 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: $kiaMidnightBlack;
  text-align: end;
}

.ant-input:not(.ant-input-disabled).priceInput {
  &:hover {
    border-color: $kiaMidnightBlack;
  }

  &:focus {
    box-shadow: none;
    border-color: $kiaMidnightBlack;
  }
}

.ant-input-number.priceInput {
  width: 126px;

  .ant-input-number-input {
    height: 100%;
    box-sizing: border-box;
    padding: 7px 24px;
    background-color: $kiaPolarWhite;
    border-radius: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: $kiaMidnightBlack;
    text-align: end;
  }
}

.ant-input-textarea.customTextarea.ant-input-textarea-show-count {
  height: 145px;
}

.ant-input-textarea.customTextarea.ant-input-textarea-show-count::after {
  float: left;
  position: absolute;
  left: 0;
  bottom: 0;
}

.ant-input-number.priceInputAdmin {
  width: 100%;

  .ant-input-number-input {
    height: 40px;
    box-sizing: border-box;
    padding: 7px 20px;
    background-color: $kiaPolarWhite;
    border-radius: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: $kiaMidnightBlack;
    text-align: start;
  }

  .ant-input-number-input[disabled] {
    background-color: #f5f5f5;
  }
}

.ant-input-number.rangeInput {
  width: 126px;
  input {
    text-align: end;
    font-weight: bold;
    font-size: 12px;
    padding-right: 24px;
  }
}

.ant-input.inputAdmin {
  height: 40px;
  box-sizing: border-box;
  padding: 7px 20px;
  background-color: $kiaPolarWhite;
  border-radius: 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: $kiaMidnightBlack;
  text-align: start;
}

.ant-input[disabled].inputAdmin {
  background-color: #f5f5f5;
}

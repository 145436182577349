@use '../utils/vars.scss' as *;

.ant-checkbox-wrapper .ant-checkbox {
  top: 3px;

  .ant-checkbox-inner {
    border-color: $kiaMidnightBlack;
    border-radius: 0;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $kiaMidnightBlack;
  }
}

.ant-checkbox.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $kiaMidnightBlack;
    border-color: $kiaMidnightBlack;
  }
}

.ant-checkbox.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    background-color: $kiaMidnightBlack;

    &::after {
      background-color: $kiaPolarWhite;
      width: 8px;
      height: 2px;
    }
  }
}

.ant-checkbox-group {
  display: flex;
  justify-content: space-between;

  .ant-checkbox-group-item {
    span {
      font-weight: bold;
      font-size: 12px;
      color: $kiaMidnightBlack;
      line-height: 12px;
    }
  }
}

.ant-radio-wrapper {
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: black;
      &::after {
        background-color: black;
      }
    }
  }
}

.ant-select-selection-item {
  font-weight: bold;
  font-size: 12px;
}

.ant-form-item-control-input-content {
  textarea {
    font-weight: bold;
    font-size: 12px;
  }
}

.adminCheckBoxGroup {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 480px;

  .ant-checkbox-wrapper {
    margin-left: 0;
  }
}
